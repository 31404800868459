<template>
    <button class="home-button" @click="redirectToHome">Home</button>
  </template>
  
  <script>
  import { useRouter } from 'vue-router';
  
  export default {
    name: 'HomeButton',
    setup() {
      const router = useRouter();
      const redirectToHome = () => {
        router.push('/dashboard');
      };
      return { redirectToHome };
    }
  };
  </script>