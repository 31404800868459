<template>
    <HomeButton />
    <br>
    <div class="dash-form">
        <h2>shortlinkydinky</h2>
        <form @submit.prevent="submitForm">
            <label for="textField">link:</label>
            <input type="text" v-model="selectedOption" id="textField" />
            <button type="submit" class="submit-button">create shortlink</button>
        </form>
        <div id="newlink">
            <v-if newlink> {{ newlink }}</v-if>
        </div>
    </div>
    <div id="newlink">
        <v-if links> {{ links }}</v-if>
    </div>


</template>

<script>
// components 
import HomeButton from '@/components/HomeButton.vue';

// imports
import { ref } from 'vue';

// vars 

// export default
export default {
    setup() {
        const selectedOption = ref('');
        const newlink = ref('');
        const links = ref('');


        const submitForm = async () => {
            try {
                console.log(`Link: ${selectedOption.value}`);

                // Simulate a POST request (replace with your actual API call)
                const response = await fetch('https://cur.cab/sl/createlink', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ url: selectedOption.value })
                });
		console.log("waiting now...")
                const data = await response.json();
		console.log(data)

                // Assuming the response contains the shortened link
                newlink.value = data;

            } catch (error) {
                console.error('Error:', error);
            }
        };

        const loadLinks = async () => {
            try {
                console.log("getting existing links...");

                const response = await fetch('https://cur.cab/sl/getlinks/1', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
		console.log("bleepbloop... working...");
                const data = await response.json();
		console.log("bleepbloop.... done??:", data);

                links.value = data;
            } catch (error) {
                console.error('Error:', error);
            }
        }
	
	loadLinks();

        return {
            selectedOption,
            newlink,
            submitForm,
            loadLinks
        };
    },
    components: {
        HomeButton
    }
};
</script>

<style>
html,
body {
    height: 100%;
    margin: 0;
}

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.dash-form {
    flex: 1;
    margin: 2%;
    padding: 2%;
    background-color: #111;
    display: flex;
    flex-direction: column;
}

.content {
    flex: 1;
    margin: 2%;
    padding: 2%;
    background-color: #111;
    display: flex;
    flex-direction: column;
}

.submit-button {
    background-color: #4CAF50;
    /* Green background */
    border: none;
    /* Remove borders */
    color: white;
    /* White text */
    padding: 7px 32px;
    /* Some padding */
    text-align: center;
    /* Centered text */
    text-decoration: none;
    /* Remove underline */
    display: inline-block;
    /* Get the element to line up horizontally */
    font-size: 16px;
    /* Increase font size */
    margin: 4px 2px;
    /* Some margin */
    cursor: pointer;
    /* Pointer/hand icon on hover */
    border-radius: 12px;
    /* Rounded corners */
    transition: background-color 0.3s;
    /* Smooth transition for hover effect */
}

.submit-button:hover {
    background-color: #45a049;
    /* Darker green on hover */
}
</style>
