<template>
  <HomeButton />
  <br>
  <div class="dash-form">
    <h2>backup (fackup v2)</h2>
    <form @submit.prevent="submitForm">
      <label for="dropdown">Tenant: </label>
      <select v-model="selectedOption">
        <option v-for="option in optionsTenant" :key="option.value" :value="option.value">
          {{ option.text }}
        </option>
      </select>
      <br>
      <label for="dropdown">Service: </label>
      <select v-model="selectedOption">
        <option v-for="option in optionsService" :key="option.value" :value="option.value">
          {{ option.text }}
        </option>
      </select>
      <br>
      <button type="submit" class="submit-button">Submit</button>
    </form>
  </div>

  <div class="content">
    <div>

      {{ currentService }}

      <ExchangeBackup v-if="currentService = 'exchange'" />
      <SharePointBackup v-if="currentService = 'sharepoint'" />
      <OneDriveBackup v-if="currentService = 'onedrive'" />
      <GroupsBackup v-if="currentService = 'groups'" />
      <BackupConfig v-if="currentService = 'config'" />
    </div>
  </div>
</template>

<script>
// components 
import HomeButton from '@/components/HomeButton.vue';

import OneDriveBackup from '@/components/Backup/OneDriveBackup.vue';
import SharePointBackup from '@/components/Backup/SharePointBackup.vue';
import ExchangeBackup from '@/components/Backup/ExchangeBackup.vue';
import GroupsBackup from '@/components/Backup/GroupsBackup.vue';
import BackupConfig from '@/components/Backup/BackupConfig.vue';

// imports
import { ref } from 'vue';

// vars 
let currentService = "exchange";

// export default
export default {
  setup() {
    const selectedTenant = ref(null);
    const optionsTenant = ref([
      { value: 'krone', text: 'krone' },
      { value: '365Lab', text: '365Lab' },
    ]);

    const selectedService = ref(null);
    const optionsService = ref([
      { value: 'config', text: 'config' },
      { value: 'sharepoint', text: 'sharepoint' },
      { value: 'exchange', text: 'exchange' },
      { value: 'onedrive', text: 'onedrive' },
      { value: 'groups', text: 'teams/groups' },
    ]);

    const submitForm = () => {
      console.log(`Selected Option: ${selectedTenant.value}, Selected Service: ${selectedService.value}`);
      
      currentService = "exchange";
      console.log(currentService)
    };

    return { selectedTenant: selectedTenant, optionsTenant: optionsTenant, optionsService: optionsService, submitForm };
  },
  components: {
    HomeButton,
    BackupConfig,
    OneDriveBackup,
    SharePointBackup,
    ExchangeBackup,
    GroupsBackup,
  },
  data() {
    return currentService;
  }
};
</script>

<style>
html,
body {
  height: 100%;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.dash-form {
  flex: 1;
  margin: 2%;
  padding: 2%;
  background-color: #111;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  margin: 2%;
  padding: 2%;
  background-color: #111;
  display: flex;
  flex-direction: column;
}

.submit-button {
  background-color: #4CAF50;
  /* Green background */
  border: none;
  /* Remove borders */
  color: white;
  /* White text */
  padding: 7px 32px;
  /* Some padding */
  text-align: center;
  /* Centered text */
  text-decoration: none;
  /* Remove underline */
  display: inline-block;
  /* Get the element to line up horizontally */
  font-size: 16px;
  /* Increase font size */
  margin: 4px 2px;
  /* Some margin */
  cursor: pointer;
  /* Pointer/hand icon on hover */
  border-radius: 12px;
  /* Rounded corners */
  transition: background-color 0.3s;
  /* Smooth transition for hover effect */
}

.submit-button:hover {
  background-color: #45a049;
  /* Darker green on hover */
}
</style>
