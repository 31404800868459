<template>
    <div>
      <div v-if="jsonData">
        <json-item :item="jsonData" />
      </div>
    </div>
  </template>
  
  <script>
  import JsonItem from './JsonItem.vue';
  
  export default {
    name: 'JsonDisplay',
    components: {
      JsonItem
    },
    data() {
      return {
        jsonData: this.getJsonData()
      };
    },
    methods: {
      getJsonData() {
        return [
          {
            "id": "91f66d1e-de2b-423b-9ebe-70a348ac987e",
            "status": "Completed",
            "version": "0",
            "protectedResourceID": "d5c9e276-c59e-4a0b-873b-7959b1ccea78",
            "protectedResourceName": "admin@krone-bochum.de",
            "owner": "d5c9e276-c59e-4a0b-873b-7959b1ccea78",
            "stats": {
              "id": "91f66d1e-de2b-423b-9ebe-70a348ac987e",
              "bytesRead": 53574,
              "bytesUploaded": 4063,
              "endedAt": "2024-07-27T16:15:11.955777718Z",
              "errorCount": 0,
              "itemsRead": 7,
              "itemsSkipped": 0,
              "itemsWritten": 1,
              "startedAt": "2024-07-27T16:15:04.815334995Z"
            }
          },
          {
            "id": "d80ef66c-9449-404e-8243-c3d4b8472d45",
            "status": "Completed",
            "version": "0",
            "protectedResourceID": "e6a0cb44-cdf2-4b37-b783-02b6a52fea0f",
            "protectedResourceName": "test@krone-bochum.de",
            "owner": "e6a0cb44-cdf2-4b37-b783-02b6a52fea0f",
            "stats": {
              "id": "d80ef66c-9449-404e-8243-c3d4b8472d45",
              "bytesRead": 9064,
              "bytesUploaded": 0,
              "endedAt": "2024-07-27T16:15:16.255693546Z",
              "errorCount": 0,
              "itemsRead": 6,
              "itemsSkipped": 0,
              "itemsWritten": 0,
              "startedAt": "2024-07-27T16:15:13.619474894Z"
            }
          },
          {
            "id": "70035e96-da5d-4842-85c2-b89b00aa01c0",
            "status": "Completed",
            "version": "0",
            "protectedResourceID": "d5c9e276-c59e-4a0b-873b-7959b1ccea78",
            "protectedResourceName": "admin@krone-bochum.de",
            "owner": "d5c9e276-c59e-4a0b-873b-7959b1ccea78",
            "stats": {
              "id": "70035e96-da5d-4842-85c2-b89b00aa01c0",
              "bytesRead": 1168182,
              "bytesUploaded": 829561,
              "endedAt": "2024-07-28T16:15:13.057949147Z",
              "errorCount": 0,
              "itemsRead": 17,
              "itemsSkipped": 0,
              "itemsWritten": 11,
              "startedAt": "2024-07-28T16:15:04.670096093Z"
            }
          },
          {
            "id": "d3f3106b-29c6-4ce2-9c9c-f9ca175fa8a2",
            "status": "Completed",
            "version": "0",
            "protectedResourceID": "e6a0cb44-cdf2-4b37-b783-02b6a52fea0f",
            "protectedResourceName": "test@krone-bochum.de",
            "owner": "e6a0cb44-cdf2-4b37-b783-02b6a52fea0f",
            "stats": {
              "id": "d3f3106b-29c6-4ce2-9c9c-f9ca175fa8a2",
              "bytesRead": 9064,
              "bytesUploaded": 0,
              "endedAt": "2024-07-28T16:15:17.375751733Z",
              "errorCount": 0,
              "itemsRead": 6,
              "itemsSkipped": 0,
              "itemsWritten": 0,
              "startedAt": "2024-07-28T16:15:14.767467877Z"
            }
          }
        ];
      }
    }
  };
  </script>
  
  <style scoped>
  .item {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
  }
  </style>
  