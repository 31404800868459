<template>
    <div class="dash-form">
        <h2>Form Example</h2>
        <form @submit.prevent="submitForm">
            <label for="name">Name:</label>
            <input type="text" v-model="name" id="name" required />
            
            <label for="dropdown">Dropdown:</label>
            <select v-model="selectedOption">
                <option v-for="option in options" :key="option.value" :value="option.value">
                    {{ option.text }}
                </option>
            </select>
            <button type="submit" class="submit-button">Submit</button>
        </form>
    </div>
</template>

<script>
import { ref } from 'vue';

export default {
    setup() {
        const name = ref('');
        const selectedOption = ref(null);
        const options = ref([
            { value: 'option1', text: 'Option 1' },
            { value: 'option2', text: 'Option 2' },
        ]);

        const submitForm = () => {
            console.log(`Name: ${name.value}, Selected Option: ${selectedOption.value}`);
        };

        return { name, selectedOption, options, submitForm };
    }
};
</script>

<style>
.submit-button {
  background-color: #4CAF50; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 7px 32px; /* Some padding */
  text-align: center; /* Centered text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Get the element to line up horizontally */
  font-size: 16px; /* Increase font size */
  margin: 4px 2px; /* Some margin */
  cursor: pointer; /* Pointer/hand icon on hover */
  border-radius: 12px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.submit-button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.dash-form {
    margin: 2%;
    padding: 2%;
    background-color: #111;
}
</style>
