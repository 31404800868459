// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Dashboard from '../views/Dashboard.vue';
import FormExample from '../views/Dashboard/Form.vue';
import DBBrowser from '../views/Dashboard/DBBrowser.vue'
import AdminPage from '../views/Dashboard/AdminPage.vue'
import StorageBrowser from '../views/Dashboard/StorageBrowser.vue'
import Jelly from '../views/Dashboard/Jelly.vue'
import Backup from '../views/Dashboard/Backup.vue';
import Shortlink from '../views/Dashboard/Shortlink.vue';


import store from '../store';
const routes = [
    { path: '/', name: 'Home', component: Home },
    { path: '/login', name: 'Login', component: Login },
    { path: '/dashboard', name: 'Dashboard', component: Dashboard, meta: { requiresAuth: true } },
    { path: '/dashboard/form', name: 'FormExample', component: FormExample, meta: { requiresAuth: true } },
    { path: '/dashboard/admin', name: 'AdminPage', component: AdminPage, meta: { requiresAuth: true } },
    { path: '/dashboard/dbbrowser', name: 'DBBrowser', component: DBBrowser, meta: { requiresAuth: true } },
    { path: '/dashboard/storagebrowser', name: 'StorageBrowser', component: StorageBrowser, meta: { requiresAuth: true } },
    { path: '/dashboard/jelly', name: 'Jelly', component: Jelly, meta: { requiresAuth: true } },
    { path: '/dashboard/backup', name: 'Backup', component: Backup, meta: { requiresAuth: true } },
    { path: '/dashboard/shortlink', name: 'Shortlink', component: Shortlink, meta: { requiresAuth: true } },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth) && !store.getters.isAuthenticated) {
        next('/login');
    } else {
        next();
    }
});

export default router;
