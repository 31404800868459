<template>
    <HomeButton />
    <h2>Gimme the jelly</h2>
    <div class="container">
        <div class="iframe-wrapper">
            <IframeEmbed iframeSrc="https://cur.cab/jelly" />
        </div>
    </div>
</template>

<script>
// components 
import HomeButton from '@/components/HomeButton.vue';
import IframeEmbed from '@/components/IframeEmbed.vue'

// export default
export default {
    components: {
        HomeButton,
        IframeEmbed
    }
};
</script>

<style>
html,
body {
  height: 100%;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  height: 60%;
}

.dash-form {
  flex: 1;
  margin: 2%;
  padding: 2%;
  background-color: #111;
  display: flex;
  flex-direction: column;
}

.iframe-wrapper {
  flex: 1;
  display: flex;
}

.iframe-wrapper iframe {
  flex: 1;
  border: none;
}
</style>
