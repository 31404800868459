<template>
    <div>
        <button class="redirect-button" @click="redirectToLogin">Login</button>
        <h1>cur.cab</h1>
        <p>...</p>

        <img src="https://s3.cur.cab/pub/the_lone_tenement_1963.10.83.jpg" alt="" style="width:75%; height:auto"/>
    </div>
	<a href="https://cur.cab">
          <img src="https://s3.cur.cab/pub/curcab_banner.gif" alt="banner-curcab">
        </a>
</template>

<script>
//import axios from 'axios';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router'; // Import useRouter from vue-router


export default {
    setup() {
        const imageSrc = ref('');
        const router = useRouter(); // Use useRouter hook to access router

        onMounted(() => {
        });

        const redirectToLogin = () => {
            router.push('/login');
        };

        return { imageSrc, redirectToLogin };
    }
};
</script>


<style>
.redirect-button {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 12px;
    transition: background-color 0.3s;
}

.redirect-button:hover {
  background-color: #45a049; /* Darker green on hover */
}
</style>
