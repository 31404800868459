<template>
    <div class="iframe-container">
      <iframe :src="iframeSrc" frameborder="0" allowfullscreen></iframe>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      iframeSrc: {
        type: String,
        required: true
      }
    }
  }
  </script>
  
  <style scoped>
  .iframe-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  </style>
  