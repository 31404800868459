<template>
  <div>
    <div v-if="jsonData">
      <json-item :item="jsonData" />
    </div>
  </div>
</template>

<script>
import JsonItem from './JsonItem.vue';

export default {
  name: 'JsonDisplay',
  components: {
    JsonItem
  },
  data() {
    return {
      jsonData: this.getJsonData()
    };
  },
  methods: {
    async getJsonData() {
      
    }
  }
};
</script>
