<template>
    <HomeButton />
    <div class="dash">
        <h1>Menu</h1>
        <nav>
            <router-link to="/dashboard/form">Form Example</router-link><br>
            <router-link to="/dashboard/DBBrowser">DB-Browser</router-link><br>
            <router-link to="/dashboard/admin">admin</router-link><br>
            <router-link to="/dashboard/jelly">media</router-link><br>
            <router-link to="/dashboard/backup">backup</router-link><br>
            <router-link to="/dashboard/shortlink">shortlink</router-link><br>
        </nav>
        <router-view>

        </router-view>
    </div>
</template>

<script>
 // components 
//import HomeButton from '@/components/HomeButton.vue';

// export default
export default {
  components: {
  }
};
</script>

<style>
.dash {
    margin: 2%;
    padding: 2%;
    background-color: #111;
}
</style>
