<template>
    <div class="container">
        <!-- Display and edit text in a styled text box -->
        <div class="editable-text-box">
            <textarea v-model="text" rows="4" cols="50"></textarea>
        </div>

        <!-- Button to fetch new text 
        <button @click="fetchText">Fetch Text</button>-->
    </div>
</template>

<script>
export default {
    data() {
        return {
            text: ''
        };
    },
    methods: {
        fetchText() {
            setTimeout(() => {
                this.text = "account_provider = 'M365'\n                aws_access_key_id = 'krone-backup'\n                aws_secret_access_key = 'cfXYSWAGkgAD0VbANAPr'\n                azure_client_id = '865bd595-5b36-45a9-99ee-0133ba89f597'\n                azure_secret = 'BNk8Q~_nltAwBakgAHqFhd8ORg.zmoHClWeprdap'\n                azure_tenantid = 'b58fa29f-f7fe-470a-af47-c6b8284ea7e0'\n                bucket = 'krone-backup'\n                corso_passphrase = 'cfXYSWAGkgAD0VbANAPr'\n                disable_tls = false\n                disable_tls_verification = false\n                endpoint = 's3.cur.cab'\n                passphrase = 'cfXYSWAGkgAD0VbANAPr'\n                prefix = ''\n                provider = 'S3'\n                repo_id = 'c5a1fced-5b0f-4c41-ae90-b51d52d8afb7'";
            }, 1000);
        }
    },
    mounted() {
        // Fetch the text when the component is mounted
        this.fetchText();
    }
};
</script>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.editable-text-box {
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
}

textarea {
    width: 100%;
    height: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    resize: none;
}

textarea:focus {
    border-color: #007BFF;
    box-shadow: 2px 2px 12px rgba(0, 123, 255, 0.5);
    outline: none;
}

button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

button:hover {
    background-color: #0056b3;
}
</style>
