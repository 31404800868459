<template>
    <div>
        <button class="home-button" @click="redirectToHome">Home</button>
        <div class="login-form">
            <h1>Login</h1>
            <form @submit.prevent="login">
                <label for="username">Username:</label>
                <br>
                <input type="text" v-model="username" id="username" required />
                <br>
                <br>
                <label for="password">Password:</label>
                <br>
                <input type="password" v-model="password" id="password" required />
                <br>
                <br>
                <button type="submit" class="login-button">Login</button>
            </form>
            <p v-if="error">{{ error }}</p>
        </div>
    </div>
</template>

<script>
import { useRouter } from 'vue-router'; // Import useRouter from vue-router

export default {
    data() {
        return {
            username: '',
            password: '',
            error: null,
        };
    },
    methods: {
        async login() {
            try {
                const response = await this.$store.dispatch('login', { username: this.username, password: this.password });
                if (response.success) {
                    this.$router.push({ name: 'Dashboard' });
                } else {
                    this.error = response.message;
                }
            } catch (error) {
                this.error = 'Login failed. Please try again.';
            }
        },
    },
    setup() {
        const router = useRouter();
        const redirectToHome = () => {
            router.push('/');
        };
        return { redirectToHome }
    }
};
</script>

<style>
.login-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 7px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 12px;
    transition: background-color 0.3s;
}

.home-button {
    position: fixed;
    top: 20px;
    left: 20px;
    background-color: #666;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 12px;
    transition: background-color 0.3s;
}


.home-button:hover {
    background-color: #777;
}

.login-button:hover {
    background-color: #45a049;
}

.login-form {
    margin: 2%;
    padding: 2%;
    margin-left: 30%;
    margin-right: 30%;
    background-color: #111;
}
</style>