<template>
  <div class="json-item">
    <table v-if="isObject(item)">
      <thead>
        <tr>
          <th>Key</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(value, key) in item" :key="key">
          <td><strong>{{ key }}</strong></td>
          <td><json-item :item="value" /></td>
        </tr>
      </tbody>
    </table>
    <table v-else-if="isArray(item)">
      <thead>
        <tr>
          <th>Index</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(value, index) in item" :key="index">
          <td>{{ index }}</td>
          <td><json-item :item="value" /></td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'JsonItem',
  props: {
    item: {
      type: [Object, Array, String, Number, Boolean, null],
      required: true
    }
  },
  methods: {
    isObject(value) {
      return typeof value === 'object' && value !== null && !Array.isArray(value);
    },
    isArray(value) {
      return Array.isArray(value);
    }
  }
};
</script>

<style scoped>
.json-item {
  margin-left: 20px;
  background-color: #111;
  padding: 10px;
  border-radius: 5px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

th, td {
  border: 1px solid darkgray;
  padding: 8px;
}

th {
  background-color: #111;
}
</style>
